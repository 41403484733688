import { toast as _toast, ToastType, CSSTransitionProps } from 'vue3-toastify'

export const useToast = () => {
  // NOTE: $toastがunknownになるため、型定義を追加
  const toast = useNuxtApp().$toast as typeof _toast

  /**
   * toast追加
   */
  const addToast = (
    text: string,
    type?: ToastType,
    time?: number,
    isClosable = false
  ) => {
    if (type === 'error') {
      toast.error(text, {
        delay: time,
        closeButton: isClosable,
      })
      return
    }
    // NOTE: 既存実装でerror以外はinfoとして扱っている
    // info以外を扱う場合は都度調整する
    toast.success(text, {
      delay: time,
      closeButton: isClosable,
    })
  }

  return {
    addToast,
  }
}

export type ToastComposable = ReturnType<typeof useToast>

export const toastInjectionKey: InjectionKey<ToastComposable> = Symbol('toast')
